import React from "react";
import LoadingState from "./states/LoadingState";
import ErrorState from "./states/ErrorState";
import { DateTime } from "luxon";
import DatePicker from "./fields/DatePicker";
import RevenueBlocks from "./reports/RevenueBlocks";
import MonthlyRevenue from "./reports/MonthlyRevenue";
import { ReactComponent as MapIcon } from "../assets/icons8-treasure-map.svg";
class ReportOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revenueByMonth: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.reportData != this.props.reportData &&
      this.props.reportData
    ) {
      if (this.props.reportData.revenueByMonth.length < 1) {
        this.setState({ revenueByMonth: [] });
        return;
      }

      const revenueByMonth = JSON.parse(
        JSON.stringify(this.props.reportData.revenueByMonth),
      );

      // Loop through revenueByMonth and parse the date parameter as a date
      revenueByMonth.forEach((month) => {
        month.date = DateTime.fromFormat(month.date, "yyyy-M").toJSDate();
      });

      //Fill in any missing months with empty data so the chart doesn't look weird, if startdate and enddate are not set use the first and last values in revenueByMonth
      const startDate = this.props.startDate
        ? this.props.startDate
        : revenueByMonth[revenueByMonth.length - 1].date;
      const endDate = this.props.endDate
        ? this.props.endDate
        : revenueByMonth[0].date;
      const months = [];
      let month = DateTime.fromJSDate(startDate);
      while (month <= DateTime.fromJSDate(endDate)) {
        let monthData = revenueByMonth.find((m) => {
          return DateTime.fromJSDate(m.date).hasSame(month, "month");
        });
        if (!monthData) {
          monthData = {
            date: month.toJSDate(),
            draft: 0,
            sent: 0,
            paid: 0,
            authorised: 0,
          };
        }
        months.push(monthData);
        month = month.plus({ months: 1 });
      }

      this.setState({ revenueByMonth: months });
    }
  }

  render() {
    return (
      <div className="">
        {this.props?.reportData ? (
          <div className="flex flex-col gap-5 p-2 divide-y divide-gray-100">
            <div className="">
              <div className="flex flex-row items-end w-full gap-10">
                <div className="flex flex-col flex-1 gap-4">
                  {this.props.breadCrumbs}

                  <h3 className="flex-1 py-3 text-4xl font-bold leading-6 text-gray-900">
                    {this.props.title}
                  </h3>
                </div>

                {this.props.status === "complete" ? (
                  <>
                    {this.props.filterComponents}

                    <div className="flex-shrink">
                      <DatePicker
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        onChange={this.props.dateChange}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {this.props.status === "no_invoices" && (
              <ErrorState
                h1="No Invoices Found"
                message={`We couldn't find any Invoice Stack invoices for this ${this.props.type}. <br /> To display in reports, invoices need to be created in Invoice Stack, or imported in.`}
                icon={<MapIcon className="my-5 w-18 h-18" />}
                supportLink="https://www.invoicestack.co/articles/how-use-revenue-reports"
              />
            )}

            {this.props.status === "complete" && (
              <>
                <div className="">
                  <RevenueBlocks
                    paidTotal={this.props.reportData.paidTotal}
                    futureTotal={this.props.reportData.futureTotal}
                    overdueTotal={this.props.reportData.overdueTotal}
                    currency={this.props.currency}
                  />
                </div>
                <div className="py-5">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Revenue per month{" "}
                  </h1>
                  <MonthlyRevenue
                    data={this.state.revenueByMonth}
                    filterTable={this.props.filterTable}
                    currency={this.props.currency}
                  />
                </div>
                <div className="w-full py-5">{this.props.table}</div>
              </>
            )}
          </div>
        ) : null}

        {this.props.status === "error" && <ErrorState />}
        {this.props.status === "token_expired_error" && (
          <ErrorState h1="Session expired" message="Please refresh the page" />
        )}
        <LoadingState show={this.props.status === "loading"} />
      </div>
    );
  }
}

export default ReportOverview;
