import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function ErrorState({
  h1 = "Could not generate reports",
  message = "Try again in a few moments. If the issue continues reach out to our support team",
  icon,
  supportLink,
}) {
  return (
    <div className="flex items-center h-48 justify-contents">
      <span className="flex flex-col items-center flex-auto">
        {icon ? (
          icon
        ) : (
          <ExclamationCircleIcon
            className="flex-auto w-10 h-10 m-3 text-red-300"
            aria-hidden="true"
          />
        )}
        <span className="flex-auto text-base text-gray-800 ">{h1}</span>
        <span
          className="flex-auto text-sm text-center text-gray-400"
          dangerouslySetInnerHTML={{ __html: message }}
        ></span>
        {supportLink && (
          <a
            href={supportLink}
            target="_blank"
            rel="noreferrer"
            className="flex-auto pt-2 text-sm text-center text-blue-500 underline"
          >
            Find out more
          </a>
        )}
      </span>
    </div>
  );
}
