import React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker, createStaticRanges } from "react-date-range";

import { DateTime } from "luxon";
import { ChevronDownIcon } from "@heroicons/react/solid";
class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  onChange(range) {
    console.log(range);
    const allTimeSelected =
      range.selection.endDate === null && range.selection.startDate === null;

    if (
      range.selection.endDate !== range.selection.startDate ||
      allTimeSelected
    ) {
      this.setState({ isEditing: false });
    }
    this.props.onChange(range);
  }

  render() {
    // const selectionRange = {
    //   startDate: new Date(),
    //   endDate: new Date(),
    //   key: "selection",
    // };
    const dateString = (
      <>
        {DateTime.fromJSDate(this.props.startDate).toLocaleString(
          DateTime.DATE_SHORT,
        )}{" "}
        -{" "}
        {DateTime.fromJSDate(this.props.endDate).toLocaleString(
          DateTime.DATE_SHORT,
        )}
      </>
    );
    return (
      <div className="relative flex items-center gap-3">
        <label className="block text-sm leading-6 text-right text-gray-900">
          Date Range
        </label>
        <div className="relative">
          <button
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={() => this.setState({ isEditing: !this.state.isEditing })}
          >
            {this.props.startDate && this.props.endDate
              ? dateString
              : "All Time"}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </button>

          {this.state.isEditing && (
            <DateRangePicker
              ranges={[
                {
                  startDate: this.props.startDate,
                  endDate: this.props.endDate,
                  key: "selection",
                },
              ]}
              className="absolute right-0 z-50 mt-4 bg-white rounded-md shadow top-full "
              onChange={this.onChange.bind(this)}
              direction="horizontal"
              months={2}
              editableDateInputs={true}
              inputRanges={[]}
              color="#0066FF"
              rangeColors="#0066FF"
              staticRanges={createStaticRanges([
                {
                  label: "All Time",
                  range: () => ({
                    startDate: null,
                    endDate: null,
                  }),
                },
                {
                  label: "Last Year",
                  range: () => ({
                    startDate: DateTime.now()
                      .startOf("year")
                      .minus({ years: 1 })
                      .toJSDate(),
                    endDate: DateTime.now()
                      .startOf("year")
                      .minus({ days: 1 })
                      .toJSDate(),
                  }),
                },
                {
                  label: "This Year",
                  range: () => ({
                    startDate: DateTime.now().startOf("year").toJSDate(),
                    endDate: DateTime.now().toJSDate(),
                  }),
                },
                {
                  label: "Last Quarter",
                  range: () => ({
                    startDate: DateTime.now()
                      .startOf("quarter")
                      .minus({ quarters: 1 })
                      .toJSDate(),
                    endDate: DateTime.now()
                      .endOf("quarter")
                      .minus({ quarters: 1 })
                      .toJSDate(),
                  }),
                },
                {
                  label: "This Quarter",
                  range: () => ({
                    startDate: DateTime.now().startOf("quarter").toJSDate(),
                    endDate: DateTime.now().endOf("quarter").toJSDate(),
                  }),
                },
                {
                  label: "Next Quarter",
                  range: () => ({
                    startDate: DateTime.now()
                      .startOf("quarter")
                      .plus({ quarters: 1 })
                      .toJSDate(),
                    endDate: DateTime.now()
                      .endOf("quarter")
                      .plus({ quarters: 1 })
                      .toJSDate(),
                  }),
                },
              ])}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DatePicker;
