import React from "react";
import Table from "../fields/Table";

const token = new URLSearchParams(document.location.search).get("token");

class CompaniesList extends React.Component {
  formatNumber(total, currency) {
    const formatter = new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: currency,
    });

    return formatter.format(total);
  }

  getData() {
    const data = [];

    this.props.data.forEach((d) => {
      data.push({
        company_name: d.name,
        owner: d.deal_owner,
        paid_revenue: this.formatNumber(d.paid, this.props.currency),
        future_revenue: this.formatNumber(d.future, this.props.currency),
        overdue_revenue: this.formatNumber(d.overdue, this.props.currency),
        company_report: `/company/${d.id}?token=${token}`,
        domain: d.domain,
      });
    });

    return data;
  }

  render() {
    const data = this.getData();

    return (
      <Table
        data={data}
        title="Revenue by company"
        description="Revenue for the selected date range, grouped by HubSpot company record"
        isFiltered={this.props.isFiltered}
        filterString={this.props.filterString}
        resetTableFilter={this.props.resetTableFilter}
        columns={[
          {
            Header: "Company Name",
            accessor: "company_name",
            Cell: ({ row, value }) => (
              <div className="flex flex-row items-center gap-2">
                <img
                  className="w-5 h-auto"
                  alt={value}
                  src={`https://logo.clearbit.com/${row.original.domain}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "https://www.invoicestack.co/sites/default/files/favicon_0.png";
                  }}
                />
                <a
                  className="text-lg font-medium text-primary"
                  href={row.original.company_report}
                >
                  {value}
                </a>

                {row.original.domain && (
                  <span className="text-gray-500 ml-2">
                    ({row.original.domain})
                  </span>
                )}
              </div>
            ),
          },
          {
            Header: "Owner",
            accessor: "owner",
          },
          {
            Header: "Paid Revenue",
            accessor: "paid_revenue",
            Cell: ({ value }) => <div className="text-gray-500">{value}</div>,
          },
          {
            Header: "Future Revenue",
            accessor: "future_revenue",
            Cell: ({ value }) => <div className="text-gray-500">{value}</div>,
          },
          {
            Header: "Overdue Revenue",
            accessor: "overdue_revenue",
            Cell: ({ value }) => <div className="text-gray-500">{value}</div>,
          },
        ]}
      />
    );
  }
}

export default CompaniesList;
