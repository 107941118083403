import React from "react";
import CompaniesList from "./reports/CompaniesList";
import Dropdown from "./fields/Dropdown";
import { DateTime } from "luxon";
import ReportOverview from "./ReportOverview";
import { ReactComponent as InvoiceStackLogo } from "../assets/invoice-stack-icon.svg";

const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class AllReportOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      startDate: false,
      endDate: false,
      connections: [],
      selectedConnection: null,
      reportData: null,
    };
  }

  componentDidMount = async () => {
    this.setState({ status: "loading" });

    try {
      const connections = await this.getConnections();
      const reportData = await this.getReportData(connections[0]);

      this.setState({
        status: "complete",
        connections,
        selectedConnection: connections[0],
        reportData,
      });
    } catch (response) {
      const errorMessage = response.body ? await response.json() : null;

      if (errorMessage?.error === "TokenExpiredError") {
        this.setState({ status: "token_expired_error" });
      } else {
        this.setState({ status: "error" });
      }
    }
  };

  getConnections = async () => {
    const response = await fetch(`${api}/reports/connections/getAll`, {
      headers: { "Content-Type": "application/json", token },
    });

    if (response.status !== 200) throw response;

    return await response.json();
  };

  getReportData = async (connection) => {
    const start = DateTime.fromJSDate(this.state.startDate).toISODate();
    const end = DateTime.fromJSDate(this.state.endDate).toISODate();
    const dateUrlSearchParams =
      start && end ? new URLSearchParams({ start, end }) : "";

    const response = await fetch(
      `${api}/reports/getAll/${connection.type}/${connection.id}/0?${dateUrlSearchParams}`,
      {
        headers: { "Content-Type": "application/json", token: token },
      },
    );

    if (response.status !== 200) throw response;

    return await response.json();
  };

  async dateChange(ranges) {
    this.setState(
      {
        status: "loading",
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      async () => {
        const reportData = await this.getReportData(
          this.state.selectedConnection,
        );
        this.setState({ status: "complete", reportData });
      },
    );
  }

  async changeConnection(value) {
    this.setState({ status: "loading" });

    const reportData = await this.getReportData(value);

    this.setState({
      status: "complete",
      selectedConnection: value,
      reportData,
    });
  }

  filterComponents = () => {
    return this.state?.connections?.length > 1 ? (
      <Dropdown
        options={this.state.connections}
        selected={this.state.selectedConnection}
        onChange={this.changeConnection.bind(this)}
      />
    ) : null;
  };

  breadCrumbs = () => {
    return (
      <div className="">
        <div className="border border-gray-200 rounded-md w-fit">
          <ul className="flex flex-row gap-2">
            <li className="flex items-center gap-2 pl-3 text-sm">
              <InvoiceStackLogo className="w-5 h-5" />
              <span>Invoice Stack</span>
            </li>
            <li className="pr-3 text-sm ">
              <a
                href={`/?token=${token}`}
                className="flex items-center gap-2 hover:text-primary "
              >
                <svg
                  className="flex-shrink-0 w-5 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <span>All Revenue</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  table = () => {
    return (
      <CompaniesList
        data={this.state.reportData.revenueByCompanies}
        currency={this.state.selectedConnection.base_currency}
      />
    );
  };

  render() {
    const title = "All Revenue";
    const filterComponents = this.state.reportData
      ? this.filterComponents()
      : null;
    const breadCrumbs = this.state.reportData ? this.breadCrumbs() : null;
    const table = this.state.reportData ? this.table() : null;

    return (
      <ReportOverview
        title={title}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        dateChange={this.dateChange.bind(this)}
        breadCrumbs={breadCrumbs}
        filterComponents={filterComponents}
        reportData={this.state.reportData}
        currency={this.state.selectedConnection?.base_currency}
        filterTable={() => {}}
        table={table}
        status={this.state.status}
      />
    );
  }
}

export default AllReportOverview;
