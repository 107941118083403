import React from "react";
import Table from "../fields/Table";
import { DateTime } from "luxon";
import { ExclamationIcon } from "@heroicons/react/outline";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  formatNumber(total, currency) {
    const formatter = new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: currency,
    });

    return formatter.format(total);
  }

  formatDate(date) {
    const isValid = DateTime.fromISO(date).isValid;

    if (!isValid) {
      return "";
    }

    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  }

  getData() {
    const data = [];

    this.props.data.forEach((d) => {
      data.push({
        invoice_number: d.external_invoicenumber,
        status: d.status,
        invoice_reference: d.reference,
        total: this.formatNumber(
          d.total - (d.credit_total_exc_tax || 0),
          d.settings.currency,
        ),
        date: this.formatDate(d.date),
        due_date: this.formatDate(d.due_date),
        overdue: d.overdue === 1 ? "Yes" : "No",
        deal_name: d.deal_name,
        owner: d.deal_owner,
        deal_pipeline: d.deal_pipeline,
        deal_stage: d.deal_stage,
        close_date: this.formatDate(d.deal_close),
        deal_link: d.deal_link,
      });
    });

    return data;
  }

  render() {
    const data = this.getData();

    return (
      <Table
        data={data}
        title="Invoices"
        description="View all invoices linked to this client for the selected date range"
        isFiltered={this.props.isFiltered}
        filterString={this.props.filterString}
        resetTableFilter={this.props.resetTableFilter}
        columns={[
          {
            Header: "Invoice Number",
            accessor: "invoice_number",
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => (
              <span className="text-gray-800 uppercase ">{value}</span>
            ),
          },
          {
            Header: "Invoice Reference",
            accessor: "invoice_reference",
            Cell: ({ value }) => <span className="break-all">{value}</span>,
          },
          {
            Header: "Total",
            accessor: "total",
            Cell: ({ value }) => <div className="text-gray-500">{value}</div>,
          },
          {
            Header: "Date",
            accessor: "date",
          },
          {
            Header: "Due Date",
            accessor: "due_date",
          },
          {
            Header: "Overdue",
            accessor: "overdue",
            Cell: ({ value }) => (
              <>
                {value === "Yes" ? (
                  <div className="flex items-center text-xs text-red-600">
                    <ExclamationIcon className="w-5 mr-1"></ExclamationIcon>
                    Overdue
                  </div>
                ) : null}
              </>
            ),
          },

          {
            Header: "Deal Name",
            accessor: "deal_name",
            Cell: ({ row, value }) => (
              <a
                className="underline text-primary"
                target="_blank"
                rel="noreferrer"
                href={row.original.deal_link}
              >
                {value}
              </a>
            ),
          },
          {
            Header: "Owner",
            accessor: "owner",
          },
          {
            Header: "Deal Pipeline",
            accessor: "deal_pipeline",
          },
          {
            Header: "Deal Stage",
            accessor: "deal_stage",
          },
          {
            Header: "Close Date",
            accessor: "close_date",
          },
        ]}
      />
    );
  }
}

export default InvoiceList;
