import React from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as LoadingAnimation } from "../../assets/invoice-anim-v2.svg";

export default function LoadingState({ show, h1 = "Loading..." }) {
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-75"
      leave="transition-opacity duration-75"
      leaveFrom="opacity-75"
      leaveTo="opacity-0"
    >
      <div className="fixed top-0 left-0 z-50 flex flex-col justify-center w-screen h-screen bg-white opacity-75">
        <div className="flex items-center h-48 justify-contents">
          <span className="flex flex-col items-center flex-auto">
            <span className="flex-auto m-3">
              <LoadingAnimation />
            </span>
            <span className="flex-auto text-base text-gray-800 ">{h1}</span>
          </span>
        </div>
      </div>
    </Transition>
  );
}
